export default {
  install(Vue) {
    Vue.loader = {
      start(type = 'full') {
        let el = document.createElement('div')
        el.classList.add('loader-element')
        let spinner = document.createElement('img')
        el.classList.add('loader')
        el.classList.add(type)
        spinner.src = '/loader.gif'
        spinner.alt = 'Loading'
        let logo = document.createElement('div')
        logo.innerHTML = 'TRADESK'
        logo.classList.add('logo')
        el.append(logo)
        el.append(spinner)
        document.body.prepend(el)
      },

      stop() {
        document.getElementsByClassName('loader-element').forEach(el => {
          el.remove()
        })
      }
    }

    Object.defineProperties(Vue.prototype, {
      $loader: {
        get() {
          return Vue.loader;
        },
      },
    });
  }
}