<template>
  <div id="app">
    <template v-if="is_loaded">
      <router-view />
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_loaded: false
    }
  },

  beforeCreate() {
    this.$loader.start()

    this.$axios.get('/api/v1/init').then(response => {
      this.$loader.stop()
      this.$store.dispatch('setAuthUser', response.data.user)
      
      if(response.data.user_is_authenticated) {
        if(this.$route.meta.guest == true) {
          this.$router.push({ name: 'dashboard' })
        }
      } else {
        if(this.$route.meta.guest != true) {
          this.$router.push({ name: 'login' })
        }
      }

      this.is_loaded = true
    })
  }
}
</script>

