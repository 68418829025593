import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

require('./theme')

// Components
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import Modal from './components/Modal'
Vue.component('modal', Modal)

import BarChart from './components/charts/Bar'
Vue.component('bar-chart', BarChart)

import LineChart from './components/charts/Line'
Vue.component('line-chart', LineChart)

import Datepicker from 'vuejs-datepicker'
Vue.component('date-picker', Datepicker)

import { DatetimePicker } from '@livelybone/vue-datepicker';
Vue.component('datetime-picker', DatetimePicker);

// Plugins
import Loader from './plugins/loader'
Vue.use(Loader)

import Auth from './plugins/auth'
Vue.use(Auth)

import Numeral from './plugins/numeral'
Vue.use(Numeral)

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Add a response interceptor
app.$axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    app.$loader.stop()
    return Promise.reject(error);
  }
);