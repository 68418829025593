import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
      meta: {
        guest: true
      }
  },
  {
    path: "/activate-account/:id",
    name: "activate-account",
    component: () =>
      import(/* webpackChunkName: "activate-account" */ "../views/auth/ActivateAccount.vue"),
      meta: {
        guest: true
      }
  },
  {
    path: "/recover-account/:id",
    name: "recover-account",
    component: () =>
      import(/* webpackChunkName: "recover-account" */ "../views/auth/RecoverAccount.vue"),
      meta: {
        guest: true
      }
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/auth/Register.vue"),
      meta: {
        guest: true
      }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/auth/ForgotPassword.vue"),
      meta: {
        guest: true
      }
  },
  {
    path: "/recover-account",
    name: "recover-account",
    component: () =>
      import(/* webpackChunkName: "recover-account" */ "../views/auth/RecoverAccount.vue"),
    meta: {
      guest: true
    }
  },
  {
    path: "/dashboard/verify-phone",
    name: "dashboard.verify-phone",
    component: () =>
      import(/* webpackChunkName: "/dashboard.verify-phone" */ "../views/auth/PhoneNumber.vue"),
    meta: {
      guest: true
    }
  },
  {
    path: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/dashboard/dashboard/Index.vue"
          ),
      },
      {
        path: "notifications",
        name: "notifications",
        component: () =>
          import(
            /* webpackChunkName: "notifications" */ "../views/dashboard/Notifications.vue"
          ),
      },
      {
        path: "app/create",
        name: "app.create",
        component: () =>
          import(
            /* webpackChunkName: "app.create" */ "../views/dashboard/app/Create.vue"
          ),
      },
      {
        path: "app/:id",
        component: () =>
          import(/* webpackChunkName: "apps" */ "../views/dashboard/App.vue"),
        children: [
          {
            path: "",
            name: "app.overview",
            component: () =>
              import(
                /* webpackChunkName: "app.overview" */ "../views/dashboard/app/Overview.vue"
              ),
          },
          {
            path: "environment",
            name: "app.environment",
            component: () =>
              import(
                /* webpackChunkName: "app.environment" */ "../views/dashboard/app/Environment.vue"
              ),
          },
          {
            path: "contact-lists",
            component: () =>
              import(
                /* webpackChunkName: "app.contacts" */ "../views/dashboard/app/ContactLists.vue"
              ),
            children: [
              {
                path: "",
                name: "app.contact-lists.index",
                component: () =>
                  import(
                    /* webpackChunkName: "app.contact-lists.index" */ "../views/dashboard/app/contact-lists/Index.vue"
                  ),
              },
              {
                path: ":list_id",
                name: "app.contact-lists.show",
                component: () =>
                  import(
                    /* webpackChunkName: "app.contact-lists.show" */ "../views/dashboard/app/contact-lists/Show.vue"
                  ),
              },
            ]
          },
          {
            path: "account-statement",
            name: "app.account-statement",
            component: () =>
              import(
                /* webpackChunkName: "app.account-statement" */ "../views/dashboard/app/AccountStatement.vue"
              ),
          },
          {
            path: "top-up",
            name: "app.top-up",
            component: () =>
              import(
                /* webpackChunkName: "app.top-up" */ "../views/dashboard/app/TopUp.vue"
              ),
          },
          {
            path: "settings",
            name: "app.settings",
            component: () =>
              import(
                /* webpackChunkName: "app.settings" */ "../views/dashboard/app/Settings.vue"
              ),
          },
          {
            path: "pricing",
            name: "app.pricing",
            component: () =>
              import(
                /* webpackChunkName: "app.pricing" */ "../views/dashboard/app/Pricing.vue"
              ),
          },
          {
            path: "members",
            name: "app.members",
            component: () =>
              import(
                /* webpackChunkName: "app.members" */ "../views/dashboard/app/Members.vue"
              ),
          },
          {
            path: "key",
            name: "app.key",
            component: () =>
              import(
                /* webpackChunkName: "app.key" */ "../views/dashboard/app/Key.vue"
              ),
          },
          {
            path: "bulk-sms",
            component: () =>
              import(
                /* webpackChunkName: "app.bulk-sms" */ "../views/dashboard/app/BulkSms.vue"
              ),
            children: [
              {
                path: "",
                name: "app.bulk-sms.analytics",
                component: () =>
                  import(
                    /* webpackChunkName: "app.bulk-sms.analytics" */ "../views/dashboard/app/bulk-sms/Analytics.vue"
                  ),
              },
              {
                path: "getting-started",
                name: "app.bulk-sms.getting-started",
                component: () =>
                  import(
                    /* webpackChunkName: "app.bulk-sms.getting-started" */ "../views/dashboard/app/bulk-sms/GettingStarted.vue"
                  ),
              },
              {
                path: "sender-id",
                name: "app.bulk-sms.sender-id",
                component: () =>
                  import(
                    /* webpackChunkName: "app.bulk-sms.sender-id" */ "../views/dashboard/app/bulk-sms/SenderId.vue"
                  ),
              },
              {
                path: "send",
                name: "app.bulk-sms.send",
                component: () =>
                  import(
                    /* webpackChunkName: "app.bulk-sms.send" */ "../views/dashboard/app/bulk-sms/Send.vue"
                  ),
              },
              {
                path: "templates",
                name: "app.bulk-sms.templates",
                component: () =>
                  import(
                    /* webpackChunkName: "app.bulk-sms.templates" */ "../views/dashboard/app/bulk-sms/Templates.vue"
                  ),
              },
              {
                path: "sent",
                name: "app.bulk-sms.sent",
                component: () =>
                  import(
                    /* webpackChunkName: "app.bulk-sms.sent" */ "../views/dashboard/app/bulk-sms/Sent.vue"
                  ),
              },
              {
                path: "outbox",
                name: "app.bulk-sms.outbox",
                component: () =>
                  import(
                    /* webpackChunkName: "app.bulk-sms.outbox" */ "../views/dashboard/app/bulk-sms/Outbox.vue"
                  ),
              },
              {
                path: "callbacks",
                name: "app.bulk-sms.callbacks",
                component: () =>
                  import(
                    /* webpackChunkName: "app.bulk-sms.callbacks" */ "../views/dashboard/app/bulk-sms/Callbacks.vue"
                  ),
              },
              {
                path: "errors",
                name: "app.bulk-sms.errors",
                component: () =>
                  import(
                    /* webpackChunkName: "app.bulk-sms.errors" */ "../views/dashboard/app/bulk-sms/Errors.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
