export default {
  state: {
    app: {},
    apps: []
  },

  getters: {
    getApp(state) {
      return state.app;
    },

    getApps(state) {
      return state.apps;
    }
  },

  mutations: {
    setApp(state, data) {
      state.app = data;
    },

    setApps(state, data) {
      state.apps = data;
    }
  },

  actions: {
    setApp({ commit }, data) {
      commit('setApp', data);
    },

    setApps({ commit }, data) {
      commit('setApps', data);
    }
  }
}